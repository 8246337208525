<template>
    <section class="oportunidades-listado-perdidas px-3 overflow-auto scroll-none">
        <titulo-divisor titulo="Lista de oportunidades perdidas">
            <div class="row">
                <div class="col-auto pl-1">
                    <filter-indicator :count="contar" @openModalFilter="abrirModalFiltros" @clearFilter="limpiarFiltro" />
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="550px" layoutServidor="prev, pager, next, jumper" :usarServidor="true" :usarPaginacion="true" :servidorData="oportunidadesPerdidas" @paginar="listarOportunidades">
            <el-table-column label="ID" prop="id" align="center" width="60">
                <template slot-scope="scope">
                    <p class="f-12">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Oportunidad" prop="nombreOp">
                <template slot-scope="scope">
                    <p class="f-12 tres-puntos cr-pointer f-600" @click="verInforOp(scope.row.id)">
                        {{ scope.row.oportunidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" align="center" prop="cliente">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Obra" align="center" prop="obra">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.obra }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Equipos" prop="cantEquipos" align="center" width="100">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.equipos }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Último ciclo" prop="ultimoCiclo">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.ultimo_ciclo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valorOp" align="center">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Probabilidad" prop="probabilidad" align="center" width="150">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.probabilidad }}%
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de perdida" prop="fechaGanada" align="center" width="150">
                <template slot-scope="scope">
                    {{ scope.row.fecha_perdida  }}
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-filtrar-op ref="filtrarOportunidad" @listar="listarOportunidades" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    components:{
        modalFiltrarOp: () => import('./partials/modalFiltrarOp')
    },
    computed: {
        ...mapGetters({
            filtros: 'oportunidades/oportunidades/filtros',
            dataFiltros: 'oportunidades/oportunidades/dataFiltros',
            numCriteriosFiltro: 'oportunidades/oportunidades/numCriteriosFiltro',
            oportunidadesPerdidas: 'oportunidades/oportunidades/oportunidadesPerdidas',
        }),
        contar(){
            return Number(this.filtros.count()) + this.numCriteriosFiltro
        }
    },
    async destroyed () {
        await this.Action_delete_filtros();
    },
    methods:{
        ...mapActions({
            Action_get_oportunidades_perdidas: 'oportunidades/oportunidades/Action_get_oportunidades_perdidas',
            Action_get_filtros_oportunidades: 'oportunidades/oportunidades/Action_get_filtros_oportunidades',
            Action_delete_filtros: 'oportunidades/oportunidades/Action_delete_filtros',
            Action_clear_filtros: 'oportunidades/oportunidades/Action_clear_filtros',
        }),
        async listarOportunidades(page = 1){
            await this.Action_get_oportunidades_perdidas({page: page, filtro: this.contar <= 0 ? undefined : 0})
        },
        async abrirModalFiltros(){
            await this.Action_get_filtros_oportunidades({tipo: 'perdidas'});
            this.$refs.filtrarOportunidad.toggle('perdidas')
        },
        verInforOp(id){
            this.$router.push({ name: 'oportunidades.ver', params: {id, tipo: 'perdidas'} })
        },
        async limpiarFiltro(){
            await this.$refs.filtrarOportunidad.cleanFilter()
        }
    }

}
</script>

<style lang="scss" scoped>
.oportunidades-listado-perdidas{
    height: calc(100vh - 225px);
}
</style>